import React from 'react';

const Header = () => {
  return (
    <header className="header">
      <h1>RoxMonitors</h1>
      {/* Hier könnten weitere Header-Elemente hinzugefügt werden */}
    </header>
  );
};

export default Header;
