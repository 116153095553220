import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Dashboard.css';

const Dashboard = ({ token }) => {
  const [modules, setModules] = useState([]);
  const [moduleNameFilter, setModuleNameFilter] = useState('');
  const [moduleName, setModuleName] = useState('');
  const [productUrl, setProductUrl] = useState('');
  const [webhookUrl, setWebhookUrl] = useState('');
  const [maxPrice, setMaxPrice] = useState('');

  useEffect(() => {
    const fetchModules = async () => {
      try {
        const result = await axios.get('http://178.254.6.230:5000/api/modules', {
          headers: { Authorization: `Bearer ${token}` }
        });
        setModules(result.data.modules);
      } catch (error) {
        console.error('Fehler beim Laden der Module:', error);
      }
    };
    fetchModules();
  }, [token]);

  const handleAddModule = async (e) => {
    e.preventDefault();
    const newModule = {
      module_name: moduleName,
      product_url: productUrl,
      webhook_url: webhookUrl,
      max_price: maxPrice
    };
    try {
      await axios.post('http://178.254.6.230:5000/api/modules', newModule, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setModules([...modules, newModule]);
    } catch (error) {
      console.error('Fehler beim Hinzufügen des Moduls:', error);
    }
  };

  const handleDeleteModule = async (moduleId) => {
    try {
      await axios.delete(`http://178.254.6.230:5000/api/modules/${moduleId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setModules(modules.filter((module) => module.id !== moduleId));
    } catch (error) {
      console.error('Fehler beim Löschen des Moduls:', error);
    }
  };
  
  const filteredModules = modules.filter(module =>
    module.module_name.toLowerCase().includes(moduleNameFilter.toLowerCase())
  );

  return (
    <div className="dashboard-container">
      <h2>Dashboard</h2>
      <form onSubmit={handleAddModule} className="module-form">
        <div>
          <label>Module Name</label>
          <select value={moduleName} onChange={(e) => setModuleName(e.target.value)} required>
            <option value="" disabled>Select a module</option>
            <option value="Hobby-Genki">Hobby-Genki</option>
            <option value="HobbyLink-Japan">HobbyLink-Japan</option>
            <option value="OkiniLand">OkiniLand</option>
            <option value="SaqraMartHobby">SaqraMartHobby</option>
            <option value="NinNinGame">NinNinGame</option>
            <option value="PlayAsia">PlayAsia</option>
          </select>
        </div>
        <div>
          <label>Product URL</label>
          <input
            type="text"
            value={productUrl}
            onChange={(e) => setProductUrl(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Webhook URL</label>
          <input
            type="text"
            value={webhookUrl}
            onChange={(e) => setWebhookUrl(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Max Price</label>
          <input
            type="number"
            id="maxPrice"
            value={maxPrice}
            onChange={(e) => setMaxPrice(e.target.value)}
            required
          />
        </div>
        <button type="submit">Add Monitor</button>
      </form>
      <ul>
          <div>
          <label htmlFor="moduleNameFilter">Filter by Module:</label>
          <select id="moduleNameFilter" value={moduleNameFilter} onChange={(e) => setModuleNameFilter(e.target.value)}>
            <option value="">All</option>
            <option value="Hobby-Genki">Hobby-Genki</option>
            <option value="HobbyLink-Japan">HobbyLink-Japan</option>
            <option value="OkiniLand">OkiniLand</option>
            <option value="SaqraMartHobby">SaqraMartHobby</option>
            <option value="NinNinGame">NinNinGame</option>
            <option value="PlayAsia">PlayAsia</option>
          </select>
        </div>
      {filteredModules.map((module) => (
        <li key={module.id}>
          {/* Module anzeigen */}
          <span>
            <strong>Module Name:</strong> {module.module_name}<br />
            <strong>Product URL:</strong> {module.product_url}<br />
            <strong>Webhook URL:</strong> {module.webhook_url}<br />
            <strong>Max Price:</strong> {module.max_price}<br />
          </span>
          <button onClick={() => handleDeleteModule(module.id)}>Delete</button>
        </li>
      ))}
    </ul>
    </div>
  );
};

export default Dashboard;
