import React from 'react';

const NotFound = () => {
  return (
    <div className="not-found">
      <h2>404 - Seite nicht gefunden</h2>
      <p>Die von Ihnen angeforderte Seite existiert nicht.</p>
    </div>
  );
};

export default NotFound;
