import React, { useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import './Login.css'; // Importiere die CSS-Datei für den Login-Stil

const Login = ({ setToken }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const history = useHistory();

  const handleSubmit = async (e) => {
  e.preventDefault();
  try {
    const response = await axios.post('http://178.254.6.230:5000/api/login', { username, password });
    const token = response.data.token;
    console.log(response.data.token);

    // Überprüfen, ob setToken eine Funktion ist, bevor wir sie aufrufen
    if (typeof setToken === 'function') {
      setToken(token);
    } else {
      // Fehlerbehandlung, wenn setToken keine Funktion ist
      console.error('setToken is not a function');
    }

    console.log(token);
    localStorage.setItem('token', token);
    history.push('/dashboard');
  } catch (error) {
    console.error('An error occurred during login:', error);
    if (error.response && error.response.data && error.response.data.message) {
      setError(error.response.data.message);
    } else {
      setError('An unexpected error occurred. Please try again later.');
    }
  }
};


  return (
    <div className="login-container"> {/* Verwende die CSS-Klasse für den Container */}
      <h2>Login</h2>
      {error && <p className="error-message">{error}</p>} {/* Verwende die CSS-Klasse für Fehlermeldungen */}
      <form onSubmit={handleSubmit}>
        <div className="form-group"> {/* Verwende die CSS-Klasse für Formulargruppen */}
          <label htmlFor="username">Username</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="form-group"> {/* Verwende die CSS-Klasse für Formulargruppen */}
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="login-button">Login</button> {/* Verwende die CSS-Klasse für den Login-Button */}
      </form>
    </div>
  );
};

Login.propTypes = {
  setToken: PropTypes.func.isRequired, // Stelle sicher, dass setToken eine Funktion ist
};

export default Login;
