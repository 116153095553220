import React from 'react';
import Login from './Login';
import './Home.css'; // Importiere die CSS-Datei für die Home-Seite

const Home = ({ setToken }) => {
  return (
    <div className="home-container"> {/* Füge der äußeren Containerklasse hinzu */}
      <h1>Welcome to RoxMonitors</h1>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a diam lectus. Sed sit amet ipsum mauris.</p>
      <Login setToken={setToken} />
    </div>
  );
};

export default Home;
